import "../../styles/previousStays.css";
import { PreviousStaysCard } from "./PreviousStaysCard";
import { Itinerary } from "../../libs/types/index";
import { sortByTimeFromToday } from "../../services/itineraryMapper";

interface ReservationCardProps {
  itineraries: Itinerary[];
}

export const PreviousStays = ({ itineraries }: ReservationCardProps) => {
  return (
    <>
      <h2 className="bb-previous-title">Tidligere opphold</h2>
      <div className="bb-previous-grid">
        {itineraries && itineraries.length ? (
          sortByTimeFromToday(itineraries).map((itinerary) => <PreviousStaysCard itinerary={itinerary} key={itinerary.itineraryId} />)
        ) : (
          <div>Du har ingen tidligere opphold</div>
        )}
      </div>
    </>
  );
};
