import React, { useEffect } from "react";
import { RegisterUserForm } from "./RegisterUserForm";

import "../../styles/newUserModal.css";

import fordelsklubbIMG from "../../assets/img/hotels/fordelsklubb.jpeg";
import logoBlack from "../../assets/img/hotels/icon-black-no-margin.png";

interface Props {
  show: boolean;
  onClose: () => void;
  setToken: (token: string) => void;
}

export const RegisterUserModal = (props: Props) => {
  useEffect(() => {
    const close = (e: any) => {
      if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
      }
    };
    window.addEventListener("keydown", close);

    return () => window.removeEventListener("keydown", close);
  });

  if (!props.show) {
    return null;
  }

  return (
    <div className="bb-reg-modal" onClick={props.onClose}>
      <div
        className="bb-modal-reg-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bb-modal-reg-body">
          <div className="bb-modal-reg-left">
            <div className="bb-modal-reg-image">
              <img src={fordelsklubbIMG} alt="DHSS Fordelsklubb" />
            </div>
            <div className="bb-empty"></div>
          </div>
          <div className="bb-modal-reg-right">
            <div className="bb-login-group">
              <img
                src={logoBlack}
                alt="De Historiske Logo"
                id="bb-login-logo"
              />
              <h2>Start ditt eventyr</h2>
              <div className="bb-sub-reg">
                <div className="bb-login-header-link">
                  Allerede medlem?{" "}
                  <a href="/fordelsklubb" className="bb-login-link">
                    Logg inn
                  </a>
                </div>
              </div>
            </div>
            <div className="bb-login-page-1">
              <RegisterUserForm setToken={props.setToken} />
            </div>
            <div className="bb-login-consent">
              <p>
                <span>
                  Ved påmelding godkjenner du at De Historiske lagrer
                  kontaktinformasjonen du gir oss, og at vi sender deg
                  nyhetsbrev om våre produkter og tjenester. Du kan oppheve
                  abonnementet når som helst. Hvis du vil ha mer informasjon om
                  vår praksis for personvern og hvordan vi forplikter oss til å
                  beskytte ditt personvern, kan du se våre retningslinjer{" "}
                  <a
                    href="https://www.dehistoriske.no/om-oss/personvernerklaring/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    her
                  </a>
                  .
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterUserModal;
