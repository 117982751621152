import {
  mapToItineraries,
  mapToShowInviteCampaign,
  mapToRewards,
  mapToCode,
} from "../services/itineraryMapper";
import {
  serverEndpoints,
  GetMeResponse,
  CredentialsResponse,
  EndpointDefinition,
} from "../config/serverEndpoints";
import { clearToken, getToken } from "./useToken";
import { Credentials } from "../libs/types";

function getRequestOptions(endpoint: EndpointDefinition): RequestInit {
  const token = getToken();
  return {
    method: endpoint.method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(token ? { Authorization: token } : {}),
    },
  };
}

async function fetchWith401Handling(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  const response = await fetch(input, init);
  if (response.status === 401) {
    clearToken();
    window.location.reload();
  }
  return response;
}

export async function getMe(): Promise<GetMeResponse> {
  const endpoint = serverEndpoints.me;
  const response = await fetchWith401Handling(
    endpoint.path,
    getRequestOptions(endpoint)
  );
  const { success, message } = await response.json();

  return {
    success: !!success,
    itineraries: success ? mapToItineraries(message) : undefined,
    code: success ? mapToCode(message) : undefined,
    showInviteCampaign: success ? mapToShowInviteCampaign(message) : undefined,
    rewards: success ? mapToRewards(message) : undefined,
  };
}

export async function login(
  credentials: Credentials
): Promise<CredentialsResponse> {
  const endpoint = serverEndpoints.login;
  const response = await fetch(endpoint.path, {
    ...getRequestOptions(endpoint),
    body: JSON.stringify(credentials),
  });
  return response.json();
}

export async function registerUser(
  credentials: Credentials
): Promise<CredentialsResponse> {
  const endpoint = serverEndpoints.register;
  const response = await fetch(endpoint.path, {
    ...getRequestOptions(endpoint),
    body: JSON.stringify(credentials),
  });
  return response.json();
}
