import React, { useState, useEffect } from "react";

import { TasteNorwayHeader } from "../components/TasteNorwayHeader";
import { BonusStatus } from "../components/BonusStatus";
import { UpcomingStays } from "../components/UpcomingStays";
import { PreviousStays } from "../components/PreviousStays";
import { Rewards, Itinerary } from "../../libs/types";
import { toUpcomingAndHistoric } from "../../services/itineraryMapper";

import { getMe } from "../../services/dhhsWebServer";

import "../../styles/globalStyles.css";

export const Fordelsklubb = () => {
  const [itineraries, setItineraries] = useState<Itinerary[] | undefined>(
    undefined
  );
  const [rewards, setRewards] = useState<Rewards | undefined>(undefined);
  const [code, setCode] = useState<string | undefined>(undefined);

  const getItineraries = async () => {
    const response = await getMe();

    if (response.success) {
      setItineraries(response.itineraries);
      setRewards(response.rewards);
      setCode(response.code);
    } else {
      //Må ha bedre error-handling
      console.log("ERROR");
    }
  };

  useEffect(() => {
    getItineraries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Må lages en bedre feilmelding her:
  if (!itineraries) {
    return (
      <>
        <div className="bb-itinerary-loader"></div>
      </>
    );
  }

  const { upcomingItineraries, historicItineraries } =
    toUpcomingAndHistoric(itineraries);

  return (
    <>
      <TasteNorwayHeader showWelcomeText={false} />
      <div className="bb-main-content">
        {rewards && <BonusStatus reward={rewards} code={code} />}
        <h2>Medlemsfordeler</h2>
        <a
          href="https://dehistoriske.no/Medlemspakker"
          className="bb-booking-btn"
        >
          Klikk her for å se medlemspakker!
        </a>
        {upcomingItineraries && (
          <UpcomingStays itineraries={upcomingItineraries} />
        )}
        {historicItineraries && (
          <PreviousStays itineraries={historicItineraries} />
        )}
      </div>
    </>
  );
};
