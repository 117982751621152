import React from "react";
import "../../styles/tasteNorwayHeader.css";

interface Props {
  showWelcomeText?: boolean;
}

export const TasteNorwayHeader = ({ showWelcomeText }: Props) => (
  <>
    <div
      className={showWelcomeText ? "bb-header" : "bb-header bb-header--small"}
    >
      <div className="bb-header-image">
        <div className="bb-header-text">
          <h2 className="bb-header-main-text">Taste Norway</h2>
          <h4 className="bb-header-sub-text">by De Historiske</h4>
          <hr />
        </div>
        {showWelcomeText && (
          <div className="bb-intro-text">
            <div style={{ textAlign: "center" }}>
              <h1 className="bb-welcome-text">
                <strong>Velkommen til vår fordelsklubb</strong>
              </h1>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>
                Her får du eksklusive invitasjoner og tilbud som vi håper at du
                vil sette pris på.&nbsp;
              </div>
              <div>Medlemsskap er gratis og du har ingen bindingstid.</div>
            </div>
          </div>
        )}
      </div>
    </div>
  </>
);
