import "../../styles/upcomingStays.css";
import { Itinerary } from "../../libs/types";
import { formatDate } from "../../services/formatDate";

interface ReservationCardProps {
  itinerary: Itinerary;
}

export const UpcomingStaysCard = ({ itinerary }: ReservationCardProps) => {
  return (
    <>
      <div className="bb-upcoming-card">
        <img src={itinerary.hotel.image} alt={itinerary.hotel.name} id="bb-card-img" />
        <div className="bb-upcoming-card-info">
          <div className="bb-card-hotel-info">
            <h3>{itinerary.hotel.name}</h3>
            <div>{itinerary.hotel.place}</div>
          </div>
          <div className="bb-upcoming-card-checkin">
            <div className="bb-checkin-details">
              <div>Innsjekk</div>
              <div>{formatDate(itinerary.stay.arrival)}</div>
            </div>
            <div className="bb-checkout-details">
              <div>Utsjekk</div>
              <div>{formatDate(itinerary.stay.departure)}</div>
            </div>
            <div className="bb-stays-details">
              <div>Netter</div>
              <div>{itinerary.stay.nights}</div>
            </div>
            <div className="bb-rooms-booked">
              <div>Rom</div>
              <div>{itinerary.stay.rooms}</div>
            </div>
          </div>
          <div className="bb-card-bonus-info">
            <div className="bb-card-bonus-nights">
              <div>Inngår i bonustelling</div>
              <div>{itinerary.stay.nights} natt</div>
            </div>
          </div>
          <div className="bb-upcoming-card-confirmation">
            <div className="bb-confirmation-details">
              <div>Reservasjonsnummer</div>
              <div>{itinerary.itineraryId}</div>
            </div>
            <div className="bb-card-changes">
              <div>
                <button>Endre</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
