import React from "react";
import "../../styles/campaignContainer.css";
import AppleIcon from "../../assets/img/icons/AppStore2.svg";
import GooglePlayIcon from "../../assets/img/icons/GooglePlay2.svg";
import StarIcon from "../../assets/img/icons/star2.svg";
import InfoIcon from "../../assets/img/icons/information2.svg";
import CalendarIcon from "../../assets/img/icons/calendar2.svg";
import { LoginForm } from "./LoginForm";

interface Props {
  setToken: (token: string) => void;
}

export const Fordelsinfo = ({ setToken }: Props) => (
  <>
    <div className="bb-campaign-page">
      <div className="bb-campaign-page-container">
        <div className="bb-campaign-page-content">
          <div>
            <h1 className="bb-campaign-title">
              <strong>Velkommen til fordelsklubben</strong>
            </h1>
            <h2 className="bb-welcome-header">Taste Norway </h2>
            <h3 className="bb-welcome-subheader">by De Historiske</h3>
          </div>
          <div className="bb-campaign-page-main-text">
            <p>
              Bli med i vår fordelsklubb og motta attraktive fordeler på våre
              unike hotell og spisesteder!<br></br>
              <br></br>
              Her vil du motta eksklusive tilbud, samt informasjon via epost om
              spennende arrangementer kun for medlemmer av fordelsklubben.
              Medlemsskap er gratis og du har ingen bindingstid.
            </p>
            <p>
              Vi er ditt vertskap og ser frem til å ta godt vare på deg. Bli med
              og ta en titt!<br></br>
            </p>
            <br></br>
            <p>
              <strong>Pass også på å laste ned vår nye fordelsapp</strong>, hvor
              du vil få følgende fordeler:<br></br>
              <br></br>· Book 5 netter – få 1 bonusnatt (les mer&nbsp;
              <a
                href="https://www.dehistoriske.no/om-oss/fordelsklubb-faq/"
                target="_blank"
                rel="noreferrer"
              >
                <strong>her</strong>
              </a>
              )*<br></br>· Bli belønnet med fordeler ved å bo på våre hotell
              <br></br>· Book opphold og opplevelser rett i appen<br></br>· Få
              tilgang til eksklusive tilbud og andre fordeler <br></br>· Tilpass
              din profil slik at du mottar relevante tilbud
            </p>
            <p>
              *Merk at bonusnatt ikke kan kombineres med eksterne
              rabattordninger.
            </p>
            <p>
              <br></br>Last ned her:
            </p>
            <div className="bb-row">
              <div className="bb-app-downloads">
                <a
                  href="https://itunes.apple.com/no/app/de-historiske/id1374527336"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={AppleIcon}
                    alt="App Store"
                    style={{ height: "50px" }}
                  ></img>
                </a>
              </div>
              <div className="bb-app-downloads">
                <a
                  href="https://play.google.com/store/apps/details?id=com.dehistoriske.fordelsapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={GooglePlayIcon}
                    alt="Google Play Store"
                    style={{ height: "50px" }}
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
        <LoginForm setToken={setToken} />
      </div>
      <div className="bb-selling-points-container">
        <ul className="bb-selling-points-list">
          <li className="bb-selling-point">
            <img src={StarIcon} alt="Star Icon"></img>
            <p className="bb-selling-point-title">Attraktive tilbud</p>
            <div>
              <p>
                Nye tilbud og gode rabatter på de beste hotellene, eksklusivt
                for våre medlemmer!
              </p>
            </div>
          </li>
          <li className="bb-selling-point">
            <img src={InfoIcon} alt="Info Icon"></img>
            <p className="bb-selling-point-title">Motta informasjon først</p>
            <div>
              <p>
                Vær en av de første som får vite akkurat hva som skjer, hvor og
                når.
              </p>
            </div>
          </li>
          <li className="bb-selling-point">
            <img src={CalendarIcon} alt="Calendar Icon"></img>
            <p className="bb-selling-point-title">Eklsusive arrangementer</p>
            <div>
              <p>
                Eksklusive arrangementer tilgjengelig kun for deg som medlem!
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </>
);
