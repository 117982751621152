import React from "react";
import { Fordelsinfo } from "../components/Fordelsinfo";
import { HomeHeader } from "../components/HomeHeader";

interface Props {
  setToken: (token: string) => void;
}

const Home = ({ setToken }: Props) => {
  return (
    <div>
      <HomeHeader />
      <Fordelsinfo setToken={setToken} />
    </div>
  );
};

export default Home;
