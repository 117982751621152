import "../../styles/loginForm.css";
import React, { useState, MouseEvent } from "react";
import RegisterUserModal from "./RegisterUserModal";
import { login } from "../../services/dhhsWebServer";
import { setEpiServerSessionCookie } from "../../services/epiServer";

interface Props {
  setToken: (token: string) => void;
}

export const LoginForm = ({ setToken }: Props) => {
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmitClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);
    const { success, message, session } = await login({ email, password });
    if (success) {
      setToken(message);
      setEpiServerSessionCookie(session);
      try {
        // @ts-ignore
        window.updateMenuButtons();
      } catch {}
    } else {
      setErrorMessage(message);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="bb-login-content">
        <div>
          <p className="bb-login-logtext">
            <strong>Logg inn</strong>
          </p>
          <h2 className="bb-login-header-text">Start ditt eventyr!</h2>
          <hr></hr>
        </div>
        <form>
          <p className="bb-login-error"></p>
          <div className="bb-login-form-group">
            <label>E-post </label>
            <input
              id="email"
              aria-label="Email"
              type="email"
              placeholder="E-post"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            ></input>
          </div>
          <div className="bb-login-form-group">
            <label>Passord</label>
            <input
              id="password"
              aria-label="Password"
              type="password"
              placeholder="Passord"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            ></input>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div style={{ textAlign: "center" }}>
            {!isSubmitting ? (
              <button
                type="submit"
                onClick={handleSubmitClick}
                className="bb-login-btn"
              >
                Logg inn
              </button>
            ) : (
              <div className="bb-itinerary-loader"></div>
            )}
            <div>
              Ikke medlem?
              <button
                type="button"
                onClick={() => setShowRegisterModal(true)}
                className="bb-nomember-reg"
              >
                Registrer deg
              </button>
            </div>

            <div className="bb-forgot-password">
              <a
                href="https://www.dehistoriske.no/account/forgotpassword"
                target="_blank"
                rel="noreferrer"
              >
                Glemt passord
              </a>
            </div>
          </div>
        </form>
      </div>
      <RegisterUserModal
        onClose={() => setShowRegisterModal(false)}
        show={showRegisterModal}
        setToken={setToken}
      />
    </>
  );
};
