import React from "react";

import "../../styles/progressBar.css";

interface Props {
  max: number;
  filled: number;
}

export const ProgressBar = ({ max, filled }: Props) => {
  const filledBars = Math.min(max, filled);
  const notFilledBars = max - filled;
  return (
    <div className="tileList">
      {Array(filledBars)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="coloredTile" />
        ))}
      {Array(notFilledBars)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="tile" />
        ))}
    </div>
  );
};
