import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Credentials } from "../../libs/types";
import { registerUser } from "../../services/dhhsWebServer";
import { setEpiServerSessionCookie } from "../../services/epiServer";
import "../../styles/reactHookForm.css";

type FormData = {
  userName: string;
  userTel: string;
  userEmail: string;
  userPassword: string;
  repeatPassword: string;
};

interface Props {
  setToken: (token: string) => void;
}

const toCredentials = (formData: FormData): Credentials => ({
  email: formData.userEmail,
  password: formData.userPassword,
  phoneNumber: formData.userTel,
});

export const RegisterUserForm = ({ setToken }: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();
  const currentPassword: string = watch("userPassword", "");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    setIsSubmitting(true);
    setErrorMessage("");
    const { success, message, session } = await registerUser(
      toCredentials(formData)
    );
    if (success) {
      setToken(message);
      setEpiServerSessionCookie(session);
    } else {
      setErrorMessage(message);
    }
    setIsSubmitting(false);
  };

  return (
    <form className="bb-react-form" onSubmit={(e) => e.preventDefault()}>
      <input
        className="bb-form-input"
        placeholder="Telefon"
        {...register("userTel", {
          required: "Du må skrive inn et gyldig 8-sifret tlf-nummer",
          pattern: /^[0-9]{8}$/,
          maxLength: {
            value: 8,
            message: "Maks 8 siffer",
          },
        })}
      />
      {errors.userTel && (
        <p className="bb-form-error">{errors.userTel.message}</p>
      )}
      <input
        className="bb-form-input"
        type="email"
        placeholder="E-post"
        {...register("userEmail", {
          required: "Du må skrive inn en e-post",
          pattern: {
            value: /^(.+)@(.+)$/,
            message: "Ugyldig format på e-post",
          },
        })}
      />
      {errors.userEmail && (
        <p className="bb-form-error">{errors.userEmail.message}</p>
      )}
      <input
        className="bb-form-input"
        type="password"
        placeholder="Passord"
        {...register("userPassword", {
          required: "Du må skrive inn et passord",
          minLength: {
            value: 6,
            message: "Passordet må inneholde minst 6 bokstaver",
          },
        })}
      />
      {errors.userPassword && (
        <p className="bb-form-error">{errors.userPassword.message}</p>
      )}

      <input
        className="bb-form-input"
        type="password"
        placeholder="Gjenta Passord"
        {...register("repeatPassword", {
          required: "Du må skrive inn passordet på nytt",
          validate: (value) =>
            value === currentPassword || "Passordene må være like",
          minLength: {
            value: 8,
            message: "Passordene må være like",
          },
        })}
      />
      {errors.repeatPassword && (
        <p className="bb-form-error">{errors.repeatPassword.message}</p>
      )}

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      {!isSubmitting ? (
        <input
          type="submit"
          className="bb-form-submit-btn"
          onClick={handleSubmit(onSubmit)}
        />
      ) : (
        <div className="bb-itinerary-loader"></div>
      )}
    </form>
  );
};
