import { Rewards, Itinerary } from "../libs/types";

export interface EndpointDefinition {
  method: string;
  path: string;
}

export interface EndpointDefinitions {
  [endpoint: string]: EndpointDefinition;
}

interface Response {
  success: boolean;
}

export interface GetMeResponse extends Response {
  itineraries?: Itinerary[];
  rewards?: Rewards;
  code?: string;
  showInviteCampaign: boolean | undefined;
}

export interface CredentialsResponse extends Response {
  message: string;
  session: string;
  status: number;
}

const serverUrl = "https://dhhs.bustbyte.no";

export const serverEndpoints: EndpointDefinitions = {
  login: {
    method: "POST",
    path: `${serverUrl}/v1/login`,
  },
  register: {
    method: "POST",
    path: `${serverUrl}/v1/register`,
  },
  forgotPassword: {
    method: "POST",
    path: `${serverUrl}/v1/forgot`,
  },
  me: {
    method: "GET",
    path: `${serverUrl}/v1/customer/me`,
  },
};
