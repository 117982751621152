import { Itinerary } from "../../libs/types";
import "../../styles/previousStays.css";
import { formatDate } from "../../services/formatDate";

interface ReservationCardProps {
  itinerary: Itinerary;
}

export const PreviousStaysCard = ({ itinerary }: ReservationCardProps) => {
  return (
    <>
      <div className="bb-grid-card" key={itinerary.itineraryId}>
        <img src={itinerary.hotel.image} id="bb-grid-card-img" alt={itinerary.hotel.name ? itinerary.hotel.name : "Bilde av hotell"}></img>
        <div className="bb-grid-hotel-info">
          <h3>{itinerary.hotel.name}</h3>
          <div className="bb-hotel-place-info">{itinerary.hotel.place}</div>
        </div>
        <div className="bb-upcoming-grid-checkin">
          <div className="bb-checkin-details">
            <div>Innsjekk</div>
            <div>{formatDate(itinerary.stay?.arrival)}</div>
          </div>
          <div className="bb-checkout-details">
            <div>Utsjekk</div>
            <div>{formatDate(itinerary.stay?.departure)}</div>
          </div>
          <div className="bb-stays-details">
            <div>Netter</div>
            <div>{itinerary.stay?.nights}</div>
          </div>
        </div>
        <div className="bb-upcoming-grid-confirmation">
          <div className="bb-confirmation-details">
            <div>Reservasjonsnummer</div>
            <div>{itinerary.itineraryId}</div>
          </div>
        </div>
      </div>
    </>
  );
};
