import "../../styles/upcomingStays.css";
import { Itinerary } from "../../libs/types";
import { UpcomingStaysCard } from "../components/UpcomingStaysCard";
import { sortByTimeFromToday } from "../../services/itineraryMapper";

interface ReservationCardProps {
  itineraries: Itinerary[];
}

export const UpcomingStays = ({ itineraries }: ReservationCardProps) => {
  return (
    <>
      <h2 className="bb-upcoming-title">Kommende opphold</h2>
      {itineraries.length ? (
        <>
          <div className="bb-upcoming-grid">
            {sortByTimeFromToday(itineraries).map((itinerary) => (
              <UpcomingStaysCard itinerary={itinerary} key={itinerary.itineraryId} />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="bb-no-upcoming-stays"> Du har ingen kommende opphold. </div>
          <div className="bb-no-upcoming-sub">NB! Etter booking kan det ta en dag før din reservasjon dukker opp på nettsiden.</div>
        </>
      )}
    </>
  );
};
