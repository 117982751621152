import { useState } from "react";

const tokenKey = "@DHHS/taste-norway-token";

export const clearToken = () => localStorage.removeItem(tokenKey);

export const getToken = () => localStorage.getItem(tokenKey);

export function useToken() {
  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: string) => {
    localStorage.setItem(tokenKey, userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
