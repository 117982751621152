import React from "react";

export const HomeHeader = () => (
  <div className="bb-header">
    <div className="bb-header-image">
      <div className="bb-header-image__photocredit">
        Foto: Georgos Gyftopoulos
      </div>
    </div>
  </div>
);
