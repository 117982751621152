import "./styles/shared.css";
import "whatwg-fetch";
import React, { useEffect } from "react";
import Home from "./views/signed-out/home";
import { Fordelsklubb } from "./views/signed-in/fordelsklubb";
import { useToken } from "./services/useToken";
import {
  getEpiServerLoginStatus,
  LoginStatus,
  logoutFromEpiServer,
} from "./services/epiServer";

export default function App() {
  const { token, setToken } = useToken();

  useEffect(() => {
    syncLoginStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Don't include dependencies here as it might result in unexpected logouts

  const syncLoginStatus = async () => {
    const loginStatus = await getEpiServerLoginStatus();
    if (loginStatus === LoginStatus.LOGGED_OUT) {
      // Not logged in in EPI, log out from app as well
      setToken("");
    }
    if (loginStatus === LoginStatus.LOGGED_IN && !token) {
      // Inconsistent status in EPI and in our app, logout everywhere
      logoutFromEpiServer();
    }
  };

  if (!token) {
    return <Home setToken={setToken} />;
  }
  return <Fordelsklubb />;
}
