export const BonusInfoText = [
  {
    heading: "Forklaring av bonusnetter",
    text: "Ofte stilte spørsmål vedrørende oppsparing av bonusnetter.",
  },
  {
    subHeading: "«Hvordan sparer man opp en bonusnatt?»",
    text: "Ved å overnatte 5 netter på ett eller flere av De Historiske sine hoteller i hvilken som helst romtype. For at overnattinger skal inngå i tellingen må de bookes enten i appen eller på www.dehistoriske.no. Bare pass på at du benytter samme e-postadresse som du er registrert med i appen når du booker. Merk at bonusnatt ikke kan kombineres med eksterne rabattordninger.",
  },
  {
    subHeading: "«Hvordan booker jeg bonusnatten min?»",
    text: "Bonusnatt må bookes 3 dager i forveien. Du kan booke bonusnatten på to måter:",
    list: [
      "Ved å gå inn på fanen som heter «Fordeler» i appen og velge pakken som heter «Bonusnatt».",
      "Eller ved å logge deg inn i fordelsklubben på www.dehistoriske.no/fordelsklubb og velge pakken som heter «Bonusnatt».",
    ],
  },
  {
    text: "* Bookingen må garanteres med et kredittkort, men du betaler 0 kroner for natten ved ankomst ved å vise koden du har mottatt i appen. Vi gjør oppmerksom på at bonusnatten må avbestilles dagen før ankomst for å unngå at bonusnatten blir registrert som brukt.",
  },
  {
    subHeading: "«Hva slags rom kan jeg bruke bonusnatten på?»",
    text: "Bonusnatten kan brukes på et standard rom for maks 2 personer inkludert frokost*, på hvilket som helst hotell i De Historiske.",
  },
  {
    text: "* Følgende hoteller inkluderer ikke frokost: Gamlebyen og Svinøya.",
  },
  {
    subHeading: "«Når blir overnattingene synlig i appen?»",
    text: "De vil bli synlig i løpet av samme dag som du sjekker ut fra hotellet. Det vil si at du ikke kan bruke en bonusnatt på samme opphold som du opptjener bonusnatten.",
  },
  {
    subHeading: "«Sparer jeg raskere når jeg har flere rom samtidig?»",
    text: "Nei, kun ett rom per døgn blir med i tellingen. Det vil for eksempel si at det kreves fortsatt 5 overnattinger selv om du har et rom til deg selv og et til barna.",
  },
  {
    subHeading: "«Kan jeg spare opp flere bonusnetter?»",
    text: "Nei, man kan kun ha én oppspart bonusnatt. Sparingen vil begynne på nytt etter at bonusnatten er benyttet.",
  },
  {
    subHeading: "«Har bonusnatten utløpsdato?»",
    text: "Ja, den må brukes innen 12 måneder.",
  },
  {
    subHeading: "«Kan jeg etterregistre bonusnetter?»",
    text: "Etterregistrering av opphold går 30 dager tilbake i tid fra første gang du logger inn i appen, og gjelder kun for bookinger som er gjort i appen eller på www.dehistoriske.no.",
  },
  {
    subHeading:
      "«Kan bonusnatten kombineres med andre tilbud, som for eksempel delbetaling til en romantisk middagspakke eller oppgradering?»",
    text: "Nei, bonusnatten må bookes som en rom-frokost overnatting i standard rom, men du står fritt til å betale for middag, oppgradering eller andre opplevelser ved innsjekk ved ledig kapasitet.",
  },
  {
    subHeading: "«Kan bonusnatten overføres til andre?»",
    text: "Nei, bonusnatten gjelder for den personen som eier medlemsskapet i fordelsklubben.",
  },
];
