import React, { useState } from "react";
import "../../styles/bonusStatus.css";
import { ProgressBar } from "./ProgressBar";
import { BonusInfoModal } from "./BonusInfoModal";
import { BONUS_NIGHT_THRESHOLD } from "../../config/settings";
import { Rewards } from "../../libs/types/index";
import { formatDate } from "../../services/formatDate";

interface BonusStatusProps {
  reward: Rewards;
  code: string | undefined;
}

export const BonusStatus = ({ reward, code }: BonusStatusProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="bb-bonus-card">
        {reward.bonusNights > 0 ? (
          <>
            <div className="bb-bonus-text">
              Du har én gratis bonusovernatting! Benytt bonusovernattingen for å
              starte tellingen på nytt.
            </div>
            {reward.expirationDate && (
              <div
                style={{
                  fontStyle: "italic",
                  marginBottom: "20px",
                  fontSize: "1.1em",
                }}
              >
                Utløper {formatDate(reward.expirationDate)}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="bb-bonus-text">Velkommen!</div>
            <div className="bb-bonus-text2">
              Du mangler nå bare {reward.nightsUntilNextBonusNight}{" "}
              {reward.nightsUntilNextBonusNight === 1 ? "natt" : "netter"} for å
              oppnå én gratis bonusnatt!
            </div>
          </>
        )}
        <button onClick={() => setShow(true)} className="bb-bonus-info-modal">
          Les mer om bonusnetter
        </button>
        {reward?.bonusNights < 1 && (
          <ProgressBar
            max={BONUS_NIGHT_THRESHOLD}
            filled={BONUS_NIGHT_THRESHOLD - reward.nightsUntilNextBonusNight}
          />
        )}
        <div>
          {reward.bonusNights > 0 ? (
            <a
              href="https://www.dehistoriske.no/Medlemspakker/bonusnatt-for-medlemmer-som-har-opptjent-6.-natt-gratis-i-appen/"
              target="_blank"
              className="bb-booking-btn"
              rel="noreferrer"
            >
              Book din bonusnatt
            </a>
          ) : (
            <a
              href="https://be.synxis.com/?chain=9083&configcode=APPSBE&level=chain&locale=nb-NO&clearcache=all&theme=APPSBE&adult=2"
              target="_blank"
              className="bb-booking-btn"
              rel="noreferrer"
            >
              Book her!
            </a>
          )}
        </div>
        <BonusInfoModal onClose={() => setShow(false)} show={show} />
      </div>

      {reward.bonusNights > 0 && (
        <div className="bb-bonus-card">
          <div className="bb-bonus-code-wrapper">
            <div className="bb-bonus-code">{code}</div>
            <div className="bb-bonus-code-info">
              Vis denne koden ved betaling på hotellet, så legger resepsjonisten
              inn bonusen din.
            </div>
          </div>
        </div>
      )}
    </>
  );
};
