import React, { useEffect } from "react";
import { BonusInfoText } from "../../content/bonusDescription";

import "../../styles/bonusModal.css";

interface Props {
  show: boolean;
  onClose: any;
}

export const BonusInfoModal = (props: Props) => {
  useEffect(() => {
    const close = (e: any) => {
      if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
      }
    };
    window.addEventListener("keydown", close);

    return () => window.removeEventListener("keydown", close);
  });

  if (!props.show) {
    return null;
  }

  return (
    <div className="bb-modal" onClick={props.onClose}>
      <div className="bb-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="bb-modal-header">
          <h2 className="bb-modal-title">Forklaring av bonusnetter</h2>
        </div>
        <div className="bb-modal-body">
          {BonusInfoText.map((text, index) => (
            <>
              {text.subHeading && (
                <div className="bb-modal-q" key={index}>
                  {text.subHeading}
                </div>
              )}
              {text.text && <div className="bb-modal-a">{text.text}</div>}
              {text.list && (
                <ul className="bb-modal-list">
                  {text.list.map((listElement) => (
                    <li>{listElement}</li>
                  ))}
                </ul>
              )}
            </>
          ))}
        </div>
        <div className="bb-modal-footer">
          <button onClick={props.onClose} className="bb-modal-close-btn">
            Lukk
          </button>
        </div>
      </div>
    </div>
  );
};
