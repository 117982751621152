export enum LoginStatus {
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
  UNKNOWN = "UNKNOWN",
}

export const getEpiServerLoginStatus = async (): Promise<LoginStatus> => {
  try {
    const response = await fetch("https://www.dehistoriske.no");
    const body = await response.text();
    if (body.includes(">Logg ut<")) {
      return LoginStatus.LOGGED_IN;
    } else {
      return LoginStatus.LOGGED_OUT;
    }
  } catch {
    return LoginStatus.UNKNOWN;
  }
};

export const logoutFromEpiServer = () => {
  window.location.href = "https://www.dehistoriske.no/account/logout";
};

export const setEpiServerSessionCookie = (session: string) => {
  // The appended path sets the cookie for the entire domain (dehistoriske.no).
  document.cookie = `${session}; path=/`;
};
