import { Itinerary, ItineraryDetail, Stay, Guest, Hotel, ItineraryMeta, Rewards } from "../libs/types";
import { isBeforeDay } from "../services/isBeforeDay";
import { addHours } from "date-fns";

interface UpcomingAndHistoricItineraries {
  upcomingItineraries: Itinerary[];
  historicItineraries: Itinerary[];
}

export const toUpcomingAndHistoric = (itineraries: Itinerary[], today: Date = new Date()): UpcomingAndHistoricItineraries => {
  const upcomingAndHistoric: UpcomingAndHistoricItineraries = {
    upcomingItineraries: [],
    historicItineraries: [],
  };
  itineraries.forEach((itinerary) => {
    if (isBeforeDay(today, itinerary.stay.departure)) {
      upcomingAndHistoric.upcomingItineraries.push({
        ...itinerary,
        isUpcoming: true,
      });
    } else {
      upcomingAndHistoric.historicItineraries.push({
        ...itinerary,
        isUpcoming: false,
      });
    }
  });
  return upcomingAndHistoric;
};

const toDate = (dateISO: string) => addHours(new Date(dateISO), 12);

const toStay = ({ nights, arrival, departure, rooms, adults, children }): Stay => ({
  nights,
  arrival: toDate(arrival),
  departure: toDate(departure),
  rooms,
  adults,
  children,
});

const toGuest = ({ name, email, phone, company, address, zip, city, countryCode }): Guest => ({
  name,
  email,
  phone,
  company,
  address,
  zip,
  city,
  countryCode,
});

const toMeta = ({ status, itineraryNumber, confirmationDate, confirmationNumber, price }): ItineraryMeta => ({
  status,
  itineraryNumber,
  confirmationDate: toDate(confirmationDate),
  confirmationNumber,
  price,
});

const toHotel = ({ id, name, place, image, latitude, longitude, email }): Hotel => ({
  id,
  name,
  place,
  image,
  latitude,
  longitude,
  email,
});

const toDetails = ({ number, meta, stay, guest, hotel }): ItineraryDetail => ({
  itineraryDetailId: number,
  meta: toMeta(meta),
  stay: toStay(stay),
  guest: toGuest(guest),
  hotel: toHotel(hotel),
});

const isDetailCancelled = (itineraryDetail: ItineraryDetail) => (itineraryDetail.meta.status || "").endsWith("X");

const isNotDetailCancelled = (itineraryDetail: ItineraryDetail) => !isDetailCancelled(itineraryDetail);

const isNotEntireStayCancelled = (itinerary: Itinerary) => !itinerary.details.every(isDetailCancelled);

const toItinerary = ({ hotel, number, details, stay, guest }): Itinerary => ({
  itineraryId: number,
  hotel: toHotel(hotel),
  details: details.map(toDetails).filter(isNotDetailCancelled),
  stay: toStay(stay),
  guest: toGuest(guest),
});

export const mapToItineraries = (message): Itinerary[] => message && message.itineraries.map(toItinerary).filter(isNotEntireStayCancelled);

export const sortByTimeFromToday = (itineraries: Itinerary[], today: Date = new Date()): Itinerary[] => {
  today.setHours(0, 0);
  return itineraries.sort((a, b) => Math.abs(today.getTime() - new Date(a.stay.arrival).getTime()) - Math.abs(today.getTime() - new Date(b.stay.arrival).getTime()));
};

export const mapToCode = (message): string | undefined => (message && message.code ? `${message.code}` : undefined);

export const mapToShowInviteCampaign = (message): boolean => !!(message && message.showInviteCampaign);

export const mapToRewards = (message): Rewards | undefined =>
  message && message.rewards
    ? {
        bonusNights: message.rewards.bonusNights,
        redeemedBonusNights: message.rewards.redeemedBonusNights,
        nightsUntilNextBonusNight: message.rewards.nightsUntilNextBonusNight,
        expirationDate: message.rewards.expirationDate && new Date(message.rewards.expirationDate),
        currentlyCountingFromDate: message.currentlyCountingFromDate,
        bookedNights: message.bookedNights,
      }
    : undefined;
